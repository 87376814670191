@import "assets/style-override/style-override";
@import "variables";

.battery-status {
  flex: 1;

  .ant-progress-line {
    .ant-progress-inner {
      background-color: #EEEEEE;
    }
  }

  .battery-status-text-error {
    color: $bat-error;
  }

  &.very-low {
    .battery-status-icon-wrapper {
      svg {
        fill: $bat-error;
      }
    }
    .battery-status-text-percentage {
      color: $bat-error;
    }
    .ant-progress-line {
      .ant-progress-bg {
        background-color: $bat-error;
      }
    }
  }

  &.low {
    .battery-status-icon-wrapper {
      svg {
        fill: $bat-warning;
      }
    }
    .battery-status-text-percentage {
      color: $bat-warning;
    }
    .ant-progress-line {
      .ant-progress-bg {
        background-color: $bat-warning;
      }
    }
  }

  &.normal {
    .battery-status-icon-wrapper {
      svg {
        fill: $bat-primary-900;
      }
    }
    .battery-status-text-percentage {
      color: $bat-primary-900;
    }
    .ant-progress-line {
      .ant-progress-bg {
        background-color: $bat-primary-900;
      }
    }
  }

  &.good {
    .battery-status-icon-wrapper {
      svg {
        fill: $bat-success;
      }
    }
    .battery-status-text-percentage {
      color: $bat-success;
    }
    .ant-progress-line {
      .ant-progress-bg {
        background-color: $bat-success;
      }
    }
  }
}
