
$bat-primary: #00AEED;
$bat-primary-50: #DFF6FB;
$bat-primary-100: #AFE6F6;
$bat-primary-200: #78D6F1;
$bat-primary-300: #38C7ED;
$bat-primary-400: #00BAED;
$bat-primary-500: #00AEED;
$bat-primary-600: #00A0DF;
$bat-primary-700: #008DCC;
$bat-primary-800: #007CBA;
$bat-primary-900: #005D9A;

$bat-primary-map: (
  default: $bat-primary,
  50: $bat-primary-50,
  100: $bat-primary-100,
  200: $bat-primary-200,
  300: $bat-primary-300,
  400: $bat-primary-400,
  500: $bat-primary-500,
  600: $bat-primary-600,
  700: $bat-primary-700,
  800: $bat-primary-800,
  900: $bat-primary-900,
  contrast: (
    50: white,
    100: black,
    200: black,
    300: black,
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);

$bat-secondary: #EEEEEE;
$bat-secondary-50: #FAFAFA;
$bat-secondary-100: #F5F5F5;
$bat-secondary-200: #EEEEEE;
$bat-secondary-300: #E0E0E0;
$bat-secondary-400: #BDBDBD;
$bat-secondary-500: #9E9E9E;
$bat-secondary-600: #757575;
$bat-secondary-700: #616161;
$bat-secondary-800: #424242;
$bat-secondary-900: #212121;

$bat-secondary-map: (
  default: $bat-secondary,
  50: $bat-secondary-50,
  100: $bat-secondary-100,
  200: $bat-secondary-200,
  300: $bat-secondary-300,
  400: $bat-secondary-400,
  500: $bat-secondary-500,
  600: $bat-secondary-600,
  700: $bat-secondary-700,
  800: $bat-secondary-800,
  900: $bat-secondary-900,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$brand-color-50: #FEFCEF;
$brand-color-100: #FEF9D1;
$brand-color-200: #FDF4B4;
$brand-color-300: #F9EA85;
$brand-color-700: #F1C76C;
$brand-color-800: #EAB262;

$bat-green-50: #F3F8EB;
$bat-green-100: #DFECCC;
$bat-green-200: #CBDFAB;
$bat-green-600: #88B153;
$bat-green-700: #759D48;
$bat-green-900: #41672A;

$bat-on-primary-medium-emphasis: rgba(255, 255, 255, 0.74);
$bat-on-surface-high-emphasis: #616161;
$bat-on-surface: #282828;
$bat-on-surface-dark-8: rgba(33, 33, 33, 0.08);
$bat-elevation-dark-12: rgba(0, 0, 0, 0.12);
$bat-surface-overlay: rgba(0, 0, 0, 0.08);

$bat-transparency-blue-10: rgba(0, 174, 237, 0.1);
$bat-transparency-yellow-20: rgba(255, 204, 74, 0.35);
$bat-transparency-green-20: rgba(117, 157, 72, 0.2);
$bat-transparency-red-20: rgba(176, 0, 32, 0.2);
$bat-transparency-red-40: rgba(176, 0, 32, 0.4);
$bat-transparency-special-blue-20: rgba(36, 24, 102, 0.2);
$bat-transparency-grey-20: rgba(33, 33, 33, 0.2);

$bat-no-transparency-special-blue-20: #CBC9D8;
$bat-no-transparency-blue-10: #DDEEF4;
$bat-no-transparency-yellow-20: #F9E7B9;
$bat-no-transparency-green-20: #DBE3D2;
$bat-no-transparency-red-20: #F5DDDD;
$bat-no-transparency-grey-20: #CBCBCB;

$bat-ui-btn-fab-color: #241866;

$bat-success: #759D48;
$bat-success-background: #DFECCC;
$bat-error: #B00020;
$bat-error-background: #F5DDDD;
$bat-warning: #DD9052;
$bat-warning-background: #FEF9D1;
$bat-info: #00AEED;
$bat-info-background: #DFF6FB;

$bat-other-colors-green-info: #62893D;
$bat-avatar-text-orange: #B17621;

$bat-logo: "../assets/logo-batt-text.svg";
$bat-top-container-background: $bat-primary-800;
$scheduler-toolbar-background: $bat-secondary-200;

$cell-color-default: #FFFFFF;
$cell-color-today: $brand-color-50;
$cell-color-weekend:  $bat-secondary-100;
$highlighted-darken-percent: 2%;
$cell-border-color: $bat-secondary-200;
$header-cell-text-color: $bat-secondary-600;

$bat-search-input-border: $bat-secondary-400;
$bat-text-placeholder: $bat-secondary-500;
$bat-text-label: $bat-secondary-600;
$bat-dialog-header-background: $bat-primary-900;

$booking-greyed-background: $bat-secondary-100;
$booking-greyed: $bat-secondary-500;
$booking-success-background: $bat-success-background;
$booking-success-background-transparency: $bat-transparency-green-20;
$booking-success: $bat-success;
$booking-warning-background: $bat-warning-background;
$booking-warning-background-transparency: $bat-transparency-yellow-20;
$booking-warning: $bat-warning;
$booking-error-background: $bat-error-background;
$booking-error-background-transparency: $bat-transparency-red-20;
$booking-error: $bat-error;
$booking-normal-background-transparency: $bat-transparency-blue-10;
$booking-normal: $bat-primary-800;

$intent-button-normal-height: 50px;
$intent-button-small-height: 32px;
$intent-button-icon-normal-height: 40px;
$intent-button-icon-small-height: 32px;
