.sof-toast-success {
  background-color: $bat-success-background;
  color: $bat-success;
  fill: $bat-success;
}

.sof-toast-error {
  background-color: $bat-error-background;
  color: $bat-error;
  fill: $bat-error;
}

.sof-toast-warning {
  background-color: $bat-warning-background;
  color: $bat-warning;
  fill: $bat-warning;
}


.sof-toast-success, .sof-toast-error, .sof-toast-warning {
  // In order to be on top of modal overlay
  z-index: 1;
  .message {
    white-space: pre-line;
  }
}

sof-input-wrapper label, .form-group-input-label {
  color: $bat-secondary-500;
  font-size: 1em;
  margin-bottom: 0em;
}

sof-input-slider {
  &.hide-number-input :nth-child(3) {
    display: none !important;
  }
}

sof-collapsable-section {
  button {
    &.btn {
      &.title {
        outline: none; // remove :focus ring around the button
        box-shadow: none;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.15px;
        color: $bat-ui-btn-fab-color;
      }
    }
  }
  // Add some space on the left for the section content
  > div {
    margin: 0em 1.5em;
  }
}

sof-input-checkbox {
  label {
    font-size: 16px;
    line-height: 1.5rem;
  }
}

// Checkbox colors
sof-input-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $bat-primary-800;
    background-color: $bat-primary-800;
  }
}

// Switch colors
sof-input-switch {
  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: $bat-primary-100;
    border-color: $bat-primary-100;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: $bat-primary-800;
  }
}

sof-input-textarea {
  &.comment {
    textarea {
      &.form-control {
        height: 10em;
      }
    }
  }
  &.json {
    textarea {
      &.form-control {
        height: 20em;
      }
    }
  }
}

sof-drop-down {
  .dropdown-item.active, .dropdown-item:active {
    background-color: $bat-primary-800;
  }
}

.gg-05 {
  grid-gap: 0.5em;
}

.gg-1 {
  grid-gap: 1em;
}

button {
  border: none;

  &.general-action-button {
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    color: $bat-on-surface;
    background: $bat-secondary-50;
    border: 1px solid $bat-secondary-300;
    box-sizing: border-box;
    border-radius: 4px;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      color: rgba(0,0,0,.26);
    }

    &.primary {
      border: none;
      background: $bat-primary;
      color: white;

      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }

    &.main-action {
      border-color: $bat-ui-btn-fab-color;
      background: $bat-ui-btn-fab-color;
      color: white;

      &:disabled {
        background-color: rgba(0, 0, 0, 0.12);
        border: 1px solid $bat-secondary-300;
      }
    }
  }

  &.main-action-button {
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    outline: none; // remove :focus ring around the button
    background-color: $bat-ui-btn-fab-color;
    border-radius: 28px;
    color: white;
    letter-spacing: 1.25px;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  &.icon-button {
    background-color: unset;
    min-width: unset;
    padding: unset;
    color: $bat-secondary-700;

    .button-wrapper {
      > span {
        display: flex;
        align-items: center;
      }
    }

    &.primary {
      color: $bat-primary;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  &.round-icon-button {
    border-radius: 50px;
    min-width: unset;
    width: 40px;
    height: 40px;
    background-color: unset;
    color: $bat-primary;

    .button-wrapper {
      line-height: 40px;
      height: 40px;
      > span {
        display: flex;
        align-items: center;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &:disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }

    &.primary {
      background-color: $bat-primary;
      color: #fff;
    }

    &.unlock {
      background: #F2F2F2;
      color: #5CB85C;
    }

    &.lock {
      background: #F2F2F2;
      color: #F44336;
    }
  }

  &.round-mini-fab-button {
    border-radius: 50px;
    min-width: unset;
    width: 40px;
    height: 40px;
    background-color: $bat-secondary-300;
    color: $bat-secondary-700;
    box-sizing: border-box;
    border: .700926px solid $bat-secondary-400;

    .button-wrapper {
      line-height: 40px;
      height: 40px;
      > span {
        display: flex;
        align-items: center;
      }
    }

    &:hover {
      opacity: 0.8;
    }

    &.primary {
      background: $bat-primary-50;
      border-color: $bat-primary-500;
      color: $bat-primary-500;
    }
  }
}

input {
  &.search-input {
    border: 1px solid $bat-search-input-border;
    box-sizing: border-box;
    border-radius: 25px;
    height: 40px;
    outline: none;
    text-indent: 10px;

    &::placeholder {
      color: $bat-text-placeholder;
    }
  }
}

.sof-card {
  position: relative;
  box-shadow: none;
  background-color: $bat-secondary-100;
  padding: 5px;
  display: flex;
  align-items: center;
  grid-gap: 0.5em;
  &.cursor-pointer {
    cursor: pointer;
  }
  &:hover:not(.no-hover) {
    background-color: $bat-secondary-50;
  }
}

.sof-toggle-button-group {
  display: flex;
  grid-gap: 0.5em;
  align-items: center;
  sof-input-radio {
    .custom-control {
      padding: 0px;

      .custom-control-label::after, .custom-control-label::before {
        display: none;
      }

      .custom-control-label {
        height: 32px;
        line-height: 32px;
        padding: 0px 12px;
        border-radius: 16px;
        background-color: $bat-on-surface-dark-8;
        border: 1px solid $bat-elevation-dark-12;
        box-sizing: border-box;
        outline: none;
        color: $bat-secondary-700;
        cursor: pointer;
      }

      .custom-control-input:checked ~ .custom-control-label {
        color: $bat-info;
        background-color: $bat-info-background;
        border: 1px solid $bat-primary-500;
      }
      .custom-control-input[disabled]:checked ~ .custom-control-label, .custom-control-input:disabled:checked ~ .custom-control-label {
        color: rgba($bat-info, 0.5);
        background-color: rgba($bat-info-background, 0.5);
        border: 1px solid rgba($bat-primary-500, 0.5);
        cursor: not-allowed;
      }
      .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
        color: rgba($bat-secondary-700, 0.125);
        background-color: rgba($bat-on-surface-dark-8, 0.125);
        border: 1px solid rgba($bat-elevation-dark-12, 0.125);
        cursor: not-allowed;
      }
    }
  }
}

sof-tabs {
  &.batt-tabs {
    .tabs {
      sof-tab {
        button, a {
          background-color: unset;
          border-top: unset;
          border-left: unset;
          border-right: unset;
          border-bottom-width: 2px;
          border-color: transparent;
          min-width: 160px;
          color: $bat-secondary-500;
          padding-bottom: 0px;
          padding-top: 0px;

          .button-wrapper {
            margin: auto;
            height: 48px;
          }

          &.selected {
            border-bottom-color: $bat-primary;
            padding-top: 0px;
            padding-bottom: 0px;
            border-bottom-width: 2px;
            font-weight: initial;
            color: $bat-secondary-800;
          }
        }
        &.disabled {
          cursor: not-allowed;
          button {
            color: $bat-secondary-300;
            pointer-events: none;
          }
        }
      }
    }
  }
}

sof-loading {
  color: $bat-primary;
  .spinner-border-md {
    width: 5rem;
    height: 5rem;
    border-width: 0.3em;
  }
}

.sof-dialog-inner-wrapper {
  .sof-dialog-inner-wrapper-header {
    background-color: $bat-primary-900;
    padding: 0px 10px !important;

    h1 {
      color: white;
      font-size: 20px;
      line-height: 50px;
      margin: 0px;
    }
  }
  .sof-dialog-inner-wrapper-footer {
    padding: 10px 20px !important;
  }
}

sof-wizard-nav-bar {
  .before-active {
    color: $bat-primary-500;
    font-weight: 500;

    .circle, .line {
      background-color: $bat-primary-500;
    }
  }

  .active {
    color: $bat-primary-800;
    font-weight: 500;

    .circle {
      background-color: $bat-primary-500;
      border: 2px solid $bat-primary-800;
    }

    .line {
      background-color: $bat-primary-800;
    }
  }
}

sof-confirm-dialog {
  // max-width and margin : needed when confirm dialog displayed on top of a sof-dialog
  max-width: 600px;
  margin: auto;
  sof-dialog-inner {
    .sof-dialog-inner-wrapper {
      .sof-dialog-inner-wrapper-header {
        // Hide close button
        button {
          display: none;
        }
      }
      .sof-dialog-inner-wrapper-body {
        padding-top: 20px;
      }
      .sof-dialog-inner-wrapper-footer {
        .button-wrapper {
          button {
            min-width: 64px;
            line-height: 36px;
            padding: 0 16px;
            color: $bat-on-surface;
            background-color: $bat-secondary-50;
            border: 1px solid $bat-secondary-300;
            box-sizing: border-box;
            border-radius: 4px;
            &:active {
              background-color: $bat-secondary-50;
              color: $bat-on-surface;
            }
            &:hover {
              opacity: 0.8;
            }
            &.order-1 {
              border-color: $bat-ui-btn-fab-color;
              background-color: $bat-ui-btn-fab-color;
              color: white;
            }
          }
        }
      }
    }
  }
}

// Dropdown menu
.ant-dropdown {
  margin-top: 3px;
  margin-bottom: 3px;
  .ant-menu {
    border: 1px solid $bat-secondary-300;
  }
}
