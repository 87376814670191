@import "~sass-rem/rem";
@import '~@angular/cdk/overlay-prebuilt.css';

@import "~bootstrap/scss/bootstrap";
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import './variables.scss';
@import './tooltip-content-common.scss';
@import './battery-status.scss';
@import 'styles/fonts';
@import 'styles/theme';

html, body, sof-root {
  height: 100%;
}

* {
  &:not(sof-license-plate .license-plate) {
    font-family: Raleway;
  }
}

// Because of view encapsulation, this css needs to be there

sof-root {
  display: flex;
  flex-direction: column;

  .top-container {
    sof-batt-top-bar-nav, batt-top-bar-nav {
      flex: 1;
      .sof-nav-link {
        color: white !important;
        font-style: normal;
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  .appContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0px;
  }
}

.cdk-overlay-container {
  .no-focus-ring {
    outline: none; // remove :focus ring around the button
  }
}

[sof-dialog-footer] {
  &.sof-vehicle-dialog-footer {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
}

sof-license-plate {
  .license-plate {
    .license-plate-euro {
      .license-plate-euro-country {
        font-family: "Roboto Mono";
        font-size: .8rem !important;
      }
    }
    .value {
      font-family: "Roboto Mono";
      min-width: 113px;
    }
  }
}

.white-icon {
  path {
    fill: white;
    fill-opacity: 1;
  }
}

.tab-restricted {
  display: none;
}

.full-height-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0px;

  &.tab-restricted {
    display: none;
  }
}

.overflow-auto {
  overflow: auto;
}

// Below css for ng-zorro
.ant-slider-handle {
  border: 2px solid $bat-primary-800;
}

.ant-slider-track {
  background-color: $bat-primary-800;
}

.justify-content-center {
  justify-content: center;
}
