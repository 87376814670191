@import "assets/style-override/style-override";
@import "variables";

sof-tooltip-content-booking, sof-tooltip-content-unavailability, sof-tooltip-content-availability,
sof-tooltip-content-pool-vehicle {
  border-radius: 0px 25px 25px 25px;

  .header {
    display: flex;
    justify-content: space-between;
    height: 24px;
    min-width: 300px;

    .title {
      padding-left: 10px;
      vertical-align: middle;
      line-height: 24px;
      color: $bat-primary-800;
      letter-spacing: 0.15px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
    }

    button {
      padding-right: 5px;
    }
  }

  button {
    outline: none;
  }

  .tooltip-list {
    padding-top: 8px;
    display: block;

    .tooltip-list-item {
      display: flex;
      flex: 1;
      height: unset !important;
      padding: 5px 10px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      position: relative;

      &.column {
        flex-direction: column;
        align-items: initial;
      }

      .tooltip-line {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        box-sizing: border-box;
      }

      .title-text {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.25px;
        color: $bat-secondary-800;
      }

      &.label-value-container {
        justify-content: space-between;

        .label {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: $bat-secondary-800;

          &.private-usage-overdue {
            color: $bat-error;
          }
        }

        .value {
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: $bat-secondary-800;
        }
      }


      &.label-component-container {
        justify-content: space-between;

        .label {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: $bat-secondary-800;
        }
      }

      &.estimated-vehicle-radius-container {
        .label {
          color: $bat-secondary-600;
          font-size: 14px;
        }
      }

      &.time-container {
        .time-title {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: $bat-secondary-800;
          padding-bottom: 3px;
        }

        .time {
          padding: 0px;
          .row:nth-of-type(1) {
            padding-bottom: 3px;
          }

          .label {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            letter-spacing: 0.4px;
            color: $bat-secondary-500;
          }
        }
      }

      &.status-container {
        justify-content: space-between;

        .status-text {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: $bat-secondary-800;
        }
        .status {
          float: right;
          box-sizing: border-box;
          border-radius: 16px;
          outline: none;
          line-height: 14px;
          padding: 0 8px;

          font-style: normal;
          font-weight: normal;
          font-size: 10px;
        }
      }

      &.user-container {
        .user-title {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.25px;
          color: $bat-secondary-800;
          padding-bottom: 3px;
        }

        .user {
          display: flex;

          sof-user-avatar {
            margin-right: 10px;
          }

          .user-info {
            line-height: 40px;
            flex: 1;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: $bat-primary-800;
          }
        }

        .comments {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: $bat-secondary-900;
          min-width: 280px;
          max-width: 280px;
        }
      }

      &.edit-container {
        justify-content: center;
        grid-gap: 0.5em;
      }
    }
  }
}
